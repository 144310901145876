import React from "react";
import Select from "react-select";
import { Wrapper } from "./style";

const CustomSelect = ({
  label,
  options,
  value,
  onChange,
  placeholder = "Select...",
  loading,
  multiple = false,
  customLable = false,
  formatOptionLabel: customFormatOptionLabel,
  required = false,
  ...props
}) => {
  const defaultFormatOptionLabel = ({ label, date }) => (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={customLable ? { fontWeight: "bolder", fontSize: "16px" } : {}}
      >
        {label}
      </div>
      {date && (
        <div
          style={{
            fontSize: "14px",
          }}
        >
          {date}
        </div>
      )}
    </div>
  );

  const formatOptionLabel = customFormatOptionLabel || defaultFormatOptionLabel;

  return (
    <Wrapper
      {...(customLable
        ? {
            placeholderColor: "#000",
            placeholderFontWeight: "500",
            minHeight: "60px !important",
          }
        : {})}
    >
      <div className="select-field-container">
        {required && <span className="required-astrix">*</span>}
        <Select
          className="select-field"
          value={
            multiple
              ? options.filter((option) => value.includes(option.value))
              : options.find((option) => option.value === value)
          }
          onChange={(selectedOption) =>
            onChange(
              multiple
                ? selectedOption.map((option) => option.value)
                : selectedOption
                ? selectedOption.value
                : null
            )
          }
          options={options}
          placeholder={placeholder}
          noOptionsMessage={() =>
            options.length > 0 ? "Searching..." : "No results found"
          }
          isMulti={multiple}
          formatOptionLabel={formatOptionLabel} // Use the determined format function
          styles={{
            option: (provided, state) => ({
              ...provided,
              padding: "12px 16px",
              cursor: "pointer",
              backgroundColor: state.isSelected
                ? "#5f2c41"
                : state.isFocused
                ? "#f9f9f9"
                : "white",
              borderBottom: "1px solid #eaeaea",
            }),
            control: (provided) => ({
              ...provided,
              borderRadius: "8px",
              padding: "4px",
              boxShadow: "none",
              borderColor: "#ddd",
              "&:hover": {
                borderColor: "#aaa",
              },
            }),
            menu: (provided) => ({
              ...provided,
              borderRadius: "8px",
              boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
              padding: "4px",
              width: "100%",
              zIndex: 1000,
            }),
          }}
          // menuIsOpen={true}
          {...props}
        />
      </div>
    </Wrapper>
  );
};

export default CustomSelect;
