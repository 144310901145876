import React from "react";
import { Wrapper } from "./style";

const ThankYouPage = () => {
  return (
    <Wrapper>
      <div className="container text-start text-lg-center mt-5">
        <div className="mb-4 d-flex justify-content-center align-items-center">
          <div className="bg-light text-center text-primary p-4 w-100 w-lg-50 fs-3 fw-semibold">
            Thank you for requesting your invite
          </div>
        </div>
        <span className="d-lg-block text-primary hero-text">Next step, </span>
        <span className="text-primary mt-n4 hero-text">
          download the{" "}
          <span className="d-contents me-1 me-lg-0 ">Experience co. </span>
          app
        </span>
        <p className="text-primary mt-7 mt-lg-0 fs-4 fs-lg-2">
          Get your invite, discover experiences, manage your adventure, meet
          your tribe on the go
        </p>
        <div className="my-0 my-lg-4 mx-n6 mx-lg-0 d-inline-grid d-lg-block">
          <a
            href="https://apps.apple.com/in/app/exco-travel-community/id6739699261"
            target="_blank"
            rel="noopener noreferrer"
            className="btn"
          >
            <img src="/media/Group.png" alt="App Store" className="img-group" />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.bhx.app"
            target="_blank"
            rel="noopener noreferrer"
            className="btn"
          >
            <img
              src="/media/googleplay.png"
              alt="Google Play"
              className="img-group"
            />
          </a>
        </div>
      </div>
    </Wrapper>
  );
};

export default ThankYouPage;
