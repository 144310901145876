import React, { useEffect, useRef, useState } from "react";
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import Toolbar from "./layout/toolbar";
import Swal from "sweetalert2";
import { ENDPOINT } from "../config";
import { toast } from "sonner";
// import MobileMenuItem from "../components/MobileMenuItem";
// import { handleLogout } from "../redux/slice";

const Page = () => {
  const { id } = useParams();
  const location = useLocation();

  const navigate = useNavigate();

  const [isOpenSidebar, setOpenSidebar] = useState(false);
  // console.log(location.pathname);
  const [currentPathname, setCurrentPathname] = useState("");

  const [editionData, setEditionData] = useState([]);
  useEffect(() => {
    if (currentPathname.includes("request-invite")) {
      getEditionData(id);
    }
  }, [currentPathname]);

  const getEditionData = async (id) => {
    try {
      const response = await fetch(
        `${ENDPOINT}/customer/api/editions/web/get-edition/?edition=${id}`
      );
      const data = await response.json();

      if (data?.message) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong while fetching the data! Redirecting to Home Page",
          confirmButtonText: "OK", // You can customize the button text if needed
          timer: 2000, // Set timer to 2000ms (2 seconds)
          customClass: {
            confirmButton: "swal-btn-custom", // Custom class for the button
          },
        });

        // Redirect to default page after showing the error
        setTimeout(() => {
          navigate("/request-invite");
        }, 2000);
      } else {
        setEditionData(data); // Set your data state here if needed}
      }
    } catch (error) {
      toast.error(error.message);
      console.error("Error fetching data:", error);
    }
  };

  // console.log(editionData, "editionData");
  useEffect(() => {
    if (isOpenSidebar) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [isOpenSidebar]);

  useEffect(() => {
    setCurrentPathname(location.pathname);
  }, [location]);

  return (
    <div>
      <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
        {/*begin::Page*/}
        <div
          className="app-page flex-column flex-column-fluid"
          id="kt_app_page"
        >
          {/*begin::Header*/}
          <div id="kt_app_header" className="app-header">
            {/*begin::Header container*/}
            <div
              className="app-container container-xxl d-flex align-items-stretch justify-content-center"
              id="kt_app_header_container"
            >
              {/*begin::Logo*/}
              <div className="d-flex justify-content-center align-items-center flex-grow-1 flex-lg-grow-1 me-lg-13">
                <a
                  className="text-primary ms-2 fs-2 fw-bold"
                  href="https://theexperience.co"
                  target="_blank"
                >
                  the experience co.
                </a>
                {/* <span className="text-primary ms-2 fs-2 fw-bold">
                  the experience co.
                </span> */}

                {/* <Link
                  to={"/request-invite"}
                  className=" text-dark d-flex justify-content-center align-items-center"
                >
                  <img
                    alt="Logo"
                    src="/media/icon-bhx.png"
                    className="h-20px h-lg-50px theme-light-show "
                  />
                  <img alt="Logo" src="/media/icon-bhx.png" class="h-20px h-lg-50px theme-dark-show" />
                  <span className="text-primary ms-2 fs-2 fw-bold">
                    the experience co.
                  </span>
                </Link> */}
              </div>
              {/*end::Logo*/}
              {/*begin::Header wrapper*/}
              {/* <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
               
                <div className="d-flex align-items-stretch">
              
                  <div
                    className={`app-header-menu app-header-mobile-drawer align-items-stretch ${
                      isOpenSidebar
                        ? "drawer drawer-start drawer-on fadeInSidebar"
                        : ""
                    } `}
                    style={{ width: isOpenSidebar ? "250px" : "" }}
                  >
                    
                    <div className="menu menu-rounded menu-column menu-lg-row menu-active-bg menu-title-gray-600 menu-state-gray-900 menu-arrow-gray-500 fw-semibold fw-semibold fs-6 align-items-stretch my-5 my-lg-0 px-2 px-lg-0">
                      {isOpenSidebar ? (
                        <MobileMenuItem
                          routesList={routesList}
                          pathname={currentPathname}
                          activeKey={activeKey}
                          setActiveKey={setActiveKey}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
            
                  </div>
             
                </div>
             
                <div className="app-navbar flex-shrink-0 position-relative">
                 
                </div>

              </div> */}
            </div>
          </div>
          {/*end::Header*/}
          {/*begin::Wrapper*/}
          <div
            className="app-wrapper flex-column flex-row-fluid"
            id="kt_app_wrapper"
          >
            <Toolbar
              editionData={editionData}
              currentPathname={currentPathname}
            />

            {/*begin::Wrapper container*/}
            <div className="app-container container-xxl d-flex">
              {/*begin::Main*/}
              <div
                className="app-main flex-column flex-row-fluid"
                id="kt_app_main"
              >
                {/*begin::Content wrapper*/}{" "}
                {isOpenSidebar && (
                  <div
                    onClick={() => setOpenSidebar(false)}
                    style={{
                      background: "#00000070",
                      position: "absolute",
                      inset: 0,
                      zIndex: "100",
                    }}
                  />
                )}
                <div className="d-flex flex-column flex-column-fluid">
                  {/*begin::Content*/}{" "}
                  <div id="kt_app_content" className="app-content">
                    <Outlet context={{ editionData }} />
                  </div>
                  {/*end::Content*/}
                </div>
                {/*end::Content wrapper*/}
                {/*begin::Footer*/}
                {/* <div id="kt_app_footer" className="app-footer pb-3">
                  <div className="app-container container-xxl d-flex flex-column flex-md-row flex-center flex-md-stack">
                    
                    <div className="text-dark order-2 order-md-1">
                      <span className="fw-semibold me-.0">Copyright © </span>
                      <a
                        href
                        target="_blank"
                        className="text-primary fw-bolder text-hover-primary"
                      >
                        Experience Co.
                      </a>
                      <span className="fw-semibold me-1">
                        {" "}
                        2024. All rights reserved.
                      </span>
                    </div>
                 
                    <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
                      <li className="menu-item">
                        <a href target="_blank" className="menu-link px-2">
                          About
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href target="_blank" className="menu-link px-2">
                          Support
                        </a>
                      </li>
                    </ul>
                   
                  </div>
                </div> */}
              </div>
            </div>

            {currentPathname.includes("thankyou") && (
              <>
                <div className="bg-light py-10 footer-banner">
                  <h1 class="text-center mb-10">Why get the app?</h1>
                  <div className="row mt-5 text-center">
                    <div className="col-md-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="41"
                        viewBox="0 0 40 41"
                        fill="none"
                        className="my-4"
                      >
                        <path
                          d="M20.832 31.7441V36.7441"
                          stroke="#502134"
                          stroke-width="1.25"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M17.5 36.7441H24.1667"
                          stroke="#502134"
                          stroke-width="1.25"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M22.5013 26.7454C16.058 26.7454 10.8346 21.5221 10.8346 15.0788C10.8346 8.63545 16.058 3.41211 22.5013 3.41211C28.9446 3.41211 34.168 8.63545 34.168 15.0788C34.168 21.5221 28.9446 26.7454 22.5013 26.7454Z"
                          stroke="#502134"
                          stroke-width="1.25"
                          stroke-linecap="round"
                        />
                        <path
                          d="M14.168 6.74544C15.2585 6.80961 15.8219 7.34376 16.7905 8.36713C18.5398 10.2155 20.2891 10.3698 21.4553 9.75364C23.2046 8.82944 21.7346 7.33246 23.7878 6.51893C25.126 5.98869 25.3126 4.54588 24.5678 3.41211"
                          stroke="#502134"
                          stroke-width="1.25"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M33.3346 16.7441C30.8346 16.7441 30.3909 18.8221 28.3346 18.4108C24.1679 17.5775 22.9873 18.509 22.9873 20.496C22.9873 22.4828 22.9873 22.4828 22.1208 23.973C21.5571 24.9423 21.3601 25.9115 22.4836 26.7441"
                          stroke="#502134"
                          stroke-width="1.25"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M10.832 3.41211C7.74643 6.41203 5.83203 10.5924 5.83203 15.2163C5.83203 24.3451 13.2939 31.7454 22.4987 31.7454C27.0412 31.7454 31.159 29.9433 34.1654 27.0204"
                          stroke="#502134"
                          stroke-width="1.25"
                          stroke-linecap="round"
                        />
                      </svg>

                      <h4 className="text-primary fw-800px fs-1 fs-lg-4 mt-2 mb-4">
                        Discover Epic Experiences
                      </h4>
                      <p className="text-secondary fw-semibold fs-4 fs-lg-5">
                        From serene beaches to bustling cities, dive into
                        journeys that inspire, challenge, and connect you with
                        incredible people. Explore experiences by vibe,
                        destination, or trip length. Whether it's a creative
                        residency, a festival, or an influencer-led trip, the
                        Experience Co. app makes your travel seamless—on your
                        terms.
                      </p>
                    </div>

                    <div className="col-md-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="41"
                        viewBox="0 0 24 24"
                        fill="none"
                        className="my-4"
                      >
                        <path
                          d="M16.8284 7.06234C18 8.12469 18 9.83451 18 13.2541V14.7459C18 18.1655 18 19.8753 16.8284 20.9377C15.6569 22 13.7712 22 10 22C6.22876 22 4.34315 22 3.17157 20.9377C2 19.8753 2 18.1655 2 14.7459V13.2541C2 9.83451 2 8.12469 3.17157 7.06234C4.34315 6 6.22876 6 10 6C13.7712 6 15.6569 6 16.8284 7.06234Z"
                          stroke="#5F2C41"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M6.06641 6C6.17344 4.61213 6.451 3.71504 7.1708 3.06234C8.34237 2 10.228 2 13.9992 2C17.7705 2 19.6561 2 20.8277 3.06234C21.9992 4.12469 21.9992 5.83451 21.9992 9.25414V10.7459C21.9992 14.1655 21.9992 15.8753 20.8277 16.9377C20.1745 17.5299 19.2993 17.792 17.9992 17.908"
                          stroke="#5F2C41"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M10.6911 10.5777L11.395 11.9972C11.491 12.1947 11.7469 12.3843 11.9629 12.4206L13.2388 12.6343C14.0547 12.7714 14.2467 13.3682 13.6587 13.957L12.6668 14.9571C12.4989 15.1265 12.4069 15.4531 12.4589 15.687L12.7428 16.925C12.9668 17.9049 12.4509 18.284 11.591 17.7718L10.3951 17.0581C10.1791 16.929 9.82315 16.929 9.60318 17.0581L8.40731 17.7718C7.5514 18.284 7.03146 17.9009 7.25543 16.925L7.5394 15.687C7.5914 15.4531 7.49941 15.1265 7.33143 14.9571L6.33954 13.957C5.7556 13.3682 5.94358 12.7714 6.75949 12.6343L8.03535 12.4206C8.24732 12.3843 8.5033 12.1947 8.59929 11.9972L9.30321 10.5777C9.68717 9.80744 10.3111 9.80744 10.6911 10.5777Z"
                          stroke="#5F2C41"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>

                      <h4 className="text-primary fw-800px  fs-1 fs-lg-4  mt-2 mb-4">
                        Join the Tribe
                      </h4>
                      <p className="text-secondary fw-semibold fs-4 fs-lg-5">
                        More than a travel, Experience Co. is a global community
                        of creators, explorers, and leaders. Discover people who
                        share your passions, connect with them before the trip,
                        and build friendships that last beyond the journey.
                        Whether you're a solo traveler or part of a group,
                        you'll feel at home with the Tribe.
                      </p>
                    </div>
                    <div className="col-md-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="41"
                        viewBox="0 0 40 41"
                        fill="none"
                        className="my-4"
                      >
                        <path
                          d="M25.0318 15.8002L25.9116 17.5746C26.0316 17.8216 26.3516 18.0585 26.6216 18.1038L28.2164 18.371C29.2363 18.5425 29.4763 19.2885 28.7413 20.0243L27.5014 21.2745C27.2914 21.4861 27.1764 21.8945 27.2414 22.1868L27.5964 23.7343C27.8764 24.9593 27.2314 25.4331 26.1566 24.793L24.6618 23.9008C24.3918 23.7395 23.9469 23.7395 23.6719 23.9008L22.1771 24.793C21.1073 25.4331 20.4573 24.9543 20.7373 23.7343L21.0923 22.1868C21.1571 21.8945 21.0423 21.4861 20.8323 21.2745L19.5924 20.0243C18.8624 19.2885 19.0974 18.5425 20.1173 18.371L21.7121 18.1038C21.9771 18.0585 22.2971 17.8216 22.4171 17.5746L23.2969 15.8002C23.7769 14.8374 24.5568 14.8374 25.0318 15.8002Z"
                          stroke="#5F2C41"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M13.332 28.4121V34.2454"
                          stroke="#5F2C41"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M13.332 5.91211V11.7454"
                          stroke="#5F2C41"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M36.6647 14.877C36.5532 12.3069 36.2407 10.6337 35.3652 9.31018C34.8615 8.54871 34.236 7.88643 33.5167 7.35324C31.5725 5.91211 28.8297 5.91211 23.3443 5.91211H16.6531C11.1676 5.91211 8.42491 5.91211 6.48071 7.35324C5.76142 7.88643 5.13579 8.54871 4.63212 9.31018C3.75677 10.6336 3.44427 12.3065 3.33272 14.8762C3.31364 15.3156 3.69214 15.6517 4.10686 15.6517C6.41649 15.6517 8.28882 17.6338 8.28882 20.0788C8.28882 22.5238 6.41649 24.5058 4.10686 24.5058C3.69214 24.5058 3.31364 24.8419 3.33272 25.2814C3.44427 27.8511 3.75677 29.5239 4.63212 30.8474C5.13579 31.6088 5.76142 32.2711 6.48071 32.8043C8.42491 34.2454 11.1676 34.2454 16.6531 34.2454H23.3443C28.8297 34.2454 31.5725 34.2454 33.5167 32.8043C34.236 32.2711 34.8615 31.6088 35.3652 30.8474C36.2407 29.5238 36.5532 27.8506 36.6647 25.2806V14.877Z"
                          stroke="#5F2C41"
                          stroke-width="1.5"
                          stroke-linejoin="round"
                        />
                      </svg>

                      <h4 className="text-primary fw-800px fs-1 fs-lg-4  mt-2 mb-4">
                        Stay Connected On-the-Go
                      </h4>
                      <p className="text-secondary fw-semibold fs-4 fs-lg-5">
                        Planning your next adventure has never been easier. The
                        Experience Co. app provides everything you need to
                        prepare, including itineraries, visa support, and
                        flexible payment options. Secure your spot with a
                        deposit, then choose a payment plan that suits your
                        needs, so you can focus on the excitement ahead.
                      </p>
                    </div>
                    <div className="col-md-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="41"
                        viewBox="0 0 40 41"
                        fill="none"
                        className="my-4"
                      >
                        <path
                          d="M3.33203 6.74545C3.33203 4.9045 4.82442 3.41211 6.66536 3.41211H24.9987C26.8396 3.41211 28.332 4.90449 28.332 6.74544V19.9121C28.332 21.7531 26.8396 23.2454 24.9987 23.2454H15.0911C15.0336 23.2454 14.9802 23.275 14.9497 23.3238L12.7887 26.7814C12.4623 27.3037 11.7018 27.3037 11.3754 26.7814L9.21432 23.3238C9.18387 23.275 9.13046 23.2454 9.07299 23.2454H6.66536C4.82442 23.2454 3.33203 21.7531 3.33203 19.9121V6.74545Z"
                          fill="#FFDD63"
                        />
                        <path
                          d="M13.332 33.2454H19.073C19.1305 33.2454 19.1839 33.275 19.2143 33.3238L21.3754 36.7814C21.7018 37.3037 22.4623 37.3037 22.7887 36.7815L24.9497 33.3238C24.9802 33.275 25.0336 33.2454 25.0911 33.2454H31.6654C34.4268 33.2454 36.6654 31.0069 36.6654 28.2454V13.4121M9.9987 10.0788H21.6654M9.9987 16.7454H16.6654M12.7887 26.7814L14.9497 23.3238C14.9802 23.275 15.0336 23.2454 15.0911 23.2454H24.9987C26.8396 23.2454 28.332 21.7531 28.332 19.9121V6.74544C28.332 4.90449 26.8396 3.41211 24.9987 3.41211H6.66536C4.82442 3.41211 3.33203 4.9045 3.33203 6.74545V19.9121C3.33203 21.7531 4.82442 23.2454 6.66536 23.2454H9.07299C9.13046 23.2454 9.18387 23.275 9.21432 23.3238L11.3754 26.7814C11.7018 27.3037 12.4623 27.3037 12.7887 26.7814Z"
                          stroke="#5F2C41"
                          stroke-width="1.53846"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>

                      <h4 className="text-primary fw-800px fs-1 fs-lg-4 mt-2 mb-4">
                        {" "}
                        Stay Connected On-the-Go
                      </h4>
                      <p className="text-secondary fw-semibold fs-4 fs-lg-5">
                        The journey doesn't end when you book. Chat with fellow
                        travelers, get updates, and reach out to our team
                        directly through the app. Stay in the loop with
                        real-time notifications, track your trip details, and
                        get support whenever you need it.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="my-10 text-center">
                  <Link
                    to={"/request-invite"}
                    className="text-primary fs-3 fw-semibold "
                  >
                    Go back to Experience Co.
                  </Link>
                </div>
              </>
            )}
          </div>
          {/*end::Wrapper*/}
        </div>
        {/*end::Page*/}
      </div>
    </div>
  );
};

export default Page;
