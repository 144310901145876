import React from "react";
import { Wrapper } from "./style";

const Toolbar = ({ editionData, currentPathname }) => {
  return (
    <>
      <Wrapper>
        {currentPathname.includes("thankyou") ? (
          <>
            {" "}
            <div>
              {/* <div class="thankyou-image"></div> */}
              <div>
                <div id="kt_app_toolbar" className="app-toolbar  w-100 ">
                  <img
                    src={
                      currentPathname.includes("thankyou")
                        ? "/media/thank_you_cover_new.jpg"
                        : ""
                    }
                    alt="logo"
                    style={{
                      height: "auto",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div>
              <div class="hero-image">
                <div class="hero-text">
                  <p>
                    Request your invite
                    {/* {editionData?.name} */}
                    {/* Request your invite for F1 Monaco Grand Prix & French
                    Riviera */}
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
      </Wrapper>
    </>
  );
};

export default Toolbar;
