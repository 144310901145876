let prodMode = false;

export const ENDPOINT = prodMode
  ? "https://api.theexperience.co"
  : "https://dev-api.theexperience.co";

export function suppressConsoleLogs() {
  if (process.env.NODE_ENV === "production") {
    for (let method of ["log", "warn", "info", "debug"]) {
      console[method] = () => {}; // Replace console methods with no-op
    }
  }
}
