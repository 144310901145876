import React, { Suspense } from "react";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import Page from "../modules/page";
import RequestInvite from "../modules/request";
import ThankYouPage from "../modules/thankyou-page";
import HomePage from "../modules/home-page";

export default function Routers({ loading, changeLanguage }) {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Navigate to="/request-invite/:id" />,
    },

    {
      path: "/",
      element: <Page />,
      children: [
        {
          path: "request-invite/:id",
          element: <RequestInvite />,
        },
        {
          path: "thankyou",
          element: <ThankYouPage />,
        },
      ],
    },
    {
      path: "request-invite",
      element: <HomePage />,
    },
    {
      path: "*",
      element: <Navigate to="/request-invite" />,
    },
  ]);

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}
