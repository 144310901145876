import styled from "styled-components";

export const Wrapper = styled.div`
  .css-13cymwt-control {
    border-style: none !important;
    box-shadow: none !important;
  }

  .css-t3ipsp-control {
    border-style: none !important;
    box-shadow: none !important;
  }

  .css-t3ipsp-control:hover {
    border-color: #fff !important;
    box-shadow: none !important;
  }

  .select-field-container {
    margin-bottom: 20px;
    position: relative;
  }

  .select-label {
    display: block;
    font-size: 14px;
    color: #6c4f7d;
    margin-bottom: 8px;
    text-align: start;
  }

  .select-field {
    width: 100%;
    font-size: 14px;
    border: none;
    border-bottom: 1px solid #d1b0cb;
    color: #5f2c41;
  }

  .css-1dimb5e-singleValue {
    color: #4c3d56;
  }

  .select-field:focus {
    outline: none;
    border-color: #6c4f7d;
  }

  .css-hlgwow {
    padding: 5px !important;
  }

  .css-1jqq78o-placeholder {
    color: ${(props) => props.placeholderColor || "#b0a3b3"};
    font-weight: ${(props) => props.placeholderFontWeight || "normal"};
    padding-left: 2px;
  }

  .css-d7l1ni-option {
    background-color: #5f2c41 !important;
    color: #fff;
  }

  .css-tr4s17-option {
    background-color: #5f2c41 !important;
    color: #fff;
  }

  .css-1u9des2-indicatorSeparator {
    display: none;
  }

  .css-13cymwt-control:focus,
  .css-13cymwt-control:active,
  .css-t3ipsp-control:focus,
  .css-t3ipsp-control:active {
    outline: none;
    border-color: #6c4f7d;
  }

  .css-qr46ko {
    padding: 0;
  }
  .css-50695z-option,
  .css-50695z-option:hover {
    border: none;
    background: #f0f0f0;
    padding: 12px 12px !important;
  }

  .css-pnj5q4-option {
    border: none;
    background: #fff;
    padding: 12px 12px !important;
  }
  .css-1twpgbo-menu {
    margin-top: 5px;
  }
  .css-1gxl6hf-control {
    border-color: unset;
    border-width: unset;
    border-style: unset;
    border-radius: unset;
    height: ${(props) => props.minHeight || "41px !important"};
    // height: 41px !important;
    padding: 0 !important;
  }

  .css-1ml5hng-option {
    color: #5f2c41;
    background-color: #fff;
  }
  .css-1xc3v61-indicatorContainer,
  .css-15lsz6c-indicatorContainer {
    padding: 2px !important;
  }

  .required-astrix {
    position: absolute;
    top: 2px;
    right: 5px;
    color: red;
    font-size: 15px;
    z-index: 1;
  }

  .css-tj5bde-Svg {
    width: 20px !important;
    margin-top: 0px !important;
    height: 15px !important;
  }
`;
