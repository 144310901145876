import React from "react";
import { Wrapper } from "./style";

const InputField = ({
  label,
  type,
  name,
  value,
  onChange,
  placeholder,
  rows = 1,
  required = false,
}) => {
  const handleFocus = (e) => {
    // Move cursor to end of input
    const val = e.target.value;
    e.target.value = "";
    e.target.value = val;
  };

  return (
    <Wrapper>
      <div className="input-field-container">
        {required && <span className="required-astrix">*</span>}
        {/* <label className="input-label">{label}</label> */}
        <textarea
          className="input-field red-astrix"
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          rows={rows} // Start with 1 row
          style={{ resize: "none", overflow: "hidden" }}
          onFocus={handleFocus}
          onInput={(e) => {
            e.target.style.height = "auto"; // Reset height to recalculate
            e.target.style.height = `${e.target.scrollHeight}px`; // Adjust height dynamically
          }}
        />

        {/* <input
          className="input-field"
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          onInput={(e) => {
            e.target.style.height = "auto"; // Reset height to recalculate
            e.target.style.height = `${e.target.scrollHeight}px`; // Adjust height dynamically
          }}
        /> */}
      </div>
    </Wrapper>
  );
};

export default InputField;
