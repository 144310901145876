import styled from "styled-components";

export const Wrapper = styled.div`
  .hero-text {
    font-size: 66px;
    font-weight: 800;
    line-height: 70px;
  }

  .img-group {
    width: 100%;
    height: 95px;
    object-fit: cover;
    border-radius: 4px;
  }

  @media (max-width: 768px) {
    .hero-text {
      font-size: 27px;
      font-weight: 800;
      line-height: 30px;
    }
    .img-group {
      height: 44px;
    }
  }
`;
