import styled from "styled-components";

export const Wrapper = styled.div`
  .hero-image {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url("/media/bg_cover.jpg");
    height: 379px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hero-text {
    text-align: center;
    font-size: 38px;
    font-weight: 800;
    // position: absolute;
    // top: 80%;
    // left: 22%;
    // transform: translate(50%, -50%);
    color: white;
    font-family: Plus Jakarta Sans, sans-serif;
    max-width: 674px;
  }

  .thankyou-image {
    background-image: url("/media/thank_you_cover_new.jpg");
    height: 572px;
    background-size: cover;
    position: relative;
  }

  .hero-image-thankyou {
    text-align: start;
    font-size: 38px;
    font-weight: 800;
    position: absolute;
    top: 80%;
    left: 35%;
    transform: translate(-50%, -50%);
    color: white;
    font-family: Plus Jakarta Sans, sans-serif;
    max-width: 674px;
  }

  @media (max-width: 768px) {
    .hero-text {
      text-align: start;
      font-size: 22px;
      font-weight: 700;
      position: absolute;
      left: 35%;
      top: 80%;
      transform: translate(-50%, -50%);
      color: white;
      font-family: Plus Jakarta Sans, sans-serif;
      max-width: 233px;
    }
  }
`;
